import { checkForValue } from "../../../helper-functions";
import { getDateFormat } from "../../dateTimeFormatFunction";

export const getEditInputFields = (data: any) => {
  const isDisabledCondition = 
  data.explorers.length === 0 &&
  new Date(data.schedule.startTimestamp).getTime() > Date.now() + 48 * 60 * 60 * 1000;
  return [
    {
      name: "classId",
      label: "Class ID",
      isDisabled: true,
      gridBreakpoints: { xs: 12, lg: 6, md: 6 },
      value: data.classId,
    },
    {
      name: "guideEmail",
      label: "Guide Email",
      isDisabled:true,
      // isDisabled: !isDisabledCondition,
      value: data.guideEmail || " ",
    },
    {
      name: "guideFirstName",
      label: "Guide First Name",
      isDisabled: !isDisabledCondition,
      value: data.guideFirstName || " ",
    },
    {
      name: "guideLastName",
      label: "Guide Last Name",
      isDisabled: !isDisabledCondition,
      value: data.guideLastName || " ",
    },
    {
      name: "guideId",
      label: "Guide ID",
      isDisabled: true,
      value: data.guideId || " ",
    },
    {
      name: "guidePhoneNo",
      label: "Guide Phone Number",
      // isDisabled: !isDisabledCondition,
      isDisabled:true,
      value: data.guidePhone || " ",
    },
    {
      name: "isClassActive",
      label: "Is Class Active",
      isDisabled: !isDisabledCondition,
      value: data.isActive ? "Yes" : "No",
    },
    {
      name: "isRepeatedClass",
      label: "Is it a repeated class (Yes/No)",
      isDisabled:true,
      // isDisabled: !isDisabledCondition,
      value: data.repeatedClass?.isRepeted ? "Yes" : "No",
    },
    {
      name: "classTitle",
      label: "Class Title",
      isDisabled: !isDisabledCondition,
      value: data.classTitle || " ",
    },
    {
      name: "classRepeatedFrom",
      label: "Class repeated from (Class ID of parent class - If repeated)",
      isDisabled: true,
      value: data.repeatedClass?.repeatedFrom || " ",
    },
    {
      name: "mainCategoryName",
      label: "Main Category Name",
      isDisabled: !isDisabledCondition,
      value: data.categoryName || " ",
    },
    {
      name: "mainCategoryId",
      label: "Main Category ID",
      isDisabled: true,
      value: data.categoryId || " ",
    },
    {
      name: "guideExperience",
      label: "Guide Experience",
      isDisabled: !isDisabledCondition,
      value: data.guideExperience || " ",
    },
    {
      name: "classDifficultyLevel",
      label: "Class Difficulty Level",
      isDisabled: !isDisabledCondition,
      value: data.classDifficultyLevel || " ",
    },

    {
      name: "scheduleEnd",
      label: "Schedule End Date",
      isDisabled:true,
      // isDisabled: !isDisabledCondition,
      value: getDateFormat(data.schedule?.startTimestamp) || " ",
    },
    {
      name: "scheduleStart",
      label: "Schedule Start Date",
      isDisabled:true,
      // isDisabled: !isDisabledCondition,
      value: getDateFormat(data.schedule?.endTimestamp) || " ",
    },
    {
      name: "pricePerClassPerPerson",
      label: "Price - Per Class Per Person (Guide Price)",
      isDisabled:true,
      // isDisabled: !isDisabledCondition,
      value: checkForValue(data.price?.pricePerClassPerPerson),
    },
    {
      name: "serviceCharge",
      label: "Price - Service Charge",
      isDisabled:true,
      // isDisabled: !isDisabledCondition,
      value: checkForValue(data.price?.serviceCharge),
      gridBreakpoints: { xs: 12, lg: 3, md: 3 },
    },
    {
      name: "tax",
      label: "Price - Tax",
      // isDisabled: !isDisabledCondition,
      isDisabled:true,
      value: checkForValue(data.price?.tax),
      gridBreakpoints: { xs: 12, lg: 3, md: 3 },
    },
    {
      name: "totalPrice",
      label: "Price - Total",
      // isDisabled: !isDisabledCondition,
      isDisabled:true,
      value: checkForValue(data.price?.total),
    },
    {
      name: "priceCurrency",
      label: "Price - Price Currency",
      // isDisabled: !isDisabledCondition,
      isDisabled:true,
      value: checkForValue(data.price?.priceCurrency),
    },
    {
      name: "minExplorer",
      label: "Minimum explorers",
      isDisabled: !isDisabledCondition,
      value: data.minExplorers || " ",
    },
    {
      name: "maxExplorer",
      label: "Maximum Explorers",
      isDisabled: !isDisabledCondition,
      value: data.maxExplorers || " ",
    },
    {
      name: "classCreatedDate",
      label: "Class Created Date",
      isDisabled: true,
      value: getDateFormat(data.createdOn) || " ",
    },
    {
      name: "classUpdatedDate",
      label: "Class Updated Date",
      isDisabled: true,
      value: getDateFormat(data.updatedOn) || " ",
    },
    {
      name: "language",
      label: "Language",
      isDisabled: !isDisabledCondition,
      value: data.languageKnown || " ",
      gridBreakpoints: { xs: 12, lg: 12, md: 12 },
    },
    {
      name: "tagsForClass",
      label: "Tags for the class*",
      isDisabled: false,
      isTagList: true,
      gridBreakpoints: { xs: 12, lg: 12, md: 12 },
      value: "",
      isEndIconVisible: true,
      tags: data.taggings?.map((tag: any) => ({ ...tag, prefix: "#" })) || [],
    },
    {
      name: "aboutClass",
      label: "About the Class*",
      isDisabled: false,
      value: data.classDesc || " ",
      gridBreakpoints: { xs: 12, lg: 12, md: 12 },
      isMultilineInput: true,
    },
    {
      name: "whatExplorerBring",
      label: "What should the explorer bring?*",
      isDisabled: false,
      value: data.additionalReqComments,
      gridBreakpoints: { xs: 12, lg: 12, md: 12 },
      isMultilineInput: true,
    },
  ];
};
