import * as React from "react";
import { useLocation, useNavigate,useParams } from "react-router-dom";

import { getGuideData } from "../../../utils/reusable-functions/guidesOrExplorers/view-guideOrExplorer/viewGuideApiData";
import { guidesOrExplorerClassColumnHeader } from "../../../utils/reusable-functions/guidesOrExplorers/view-guideOrExplorer/tableHeader";
import { DetailsViewPageComponent } from "../../../compoundComponents";
import { getGuideOrExplorerClass } from "../../../utils/reusable-functions/guidesOrExplorers/view-guideOrExplorer/guideExplorerClassTableData";
import { updateGuideController } from "src/controllers/guidesController";
import useMessage from "../../../hooks/customHooks/useMessage";
import { errorConst } from "../../../utils/text-constants";
export default function ViewGuidePage() {
  const navigate = useNavigate();
  const location: any = useLocation();
  const { userId } = useParams<{ userId: string }>();
  const [viewData, setViewData] = React.useState<any>({
    data: null,
    isLoading: false,
    guideImage: null,
    guideName: null,
  });

  const [pageState, setPageState] = React.useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 0,
    pageSize: 10,
  });
  const [isUpdating, setIsUpdating] = React.useState<boolean>(false);
  const { setMsg } = useMessage();
  React.useEffect(() => {
    const userIds = userId ? userId : location?.state?.guideId;
    if (userIds) {
      getGuideData(userIds, setViewData);
    }
  }, [userId,isUpdating,location?.state?.guideId]);

  React.useEffect(() => {
    if (location?.state?.guideId) {
      getGuideOrExplorerClass(location.state.guideId, setPageState, pageState, "guide");
    }
  }, [pageState.page, pageState.pageSize]);
  const handleButtonClick = (status:any) => {
        // make api call
        const finalPayload = status === 'Approve' ? { becomeGuideStatus: 'Approved' } : { becomeGuideStatus: 'Rejected' }
        const profileStatus = status === 'Approve' ? 'Approved' : 'Rejected';
        // API call to upade class
        updateGuideController(
          () => {
            setIsUpdating(true);
            setMsg({
              show: true,
              type: "success",
              msg: `Guide profile ${profileStatus} successfully!`,
              errorType: "default",
            });
          },
          (error: any) => {
            setIsUpdating(true);
            setMsg({
              show: true,
              type: "error",
              msg: error?.data?.message || error?.message || errorConst.UNKNOW_ERROR,
              errorType: "default",
            });
          },
          userId,
          finalPayload
        );
     
  }
  return (
    <DetailsViewPageComponent
      pageState={pageState}
      setPageState={setPageState}
      viewData={{
        ...viewData,
        profileImage: viewData?.guideImage,
        name: viewData?.guideName,
      }}
      button1={{
        handleClick: () => userId ? navigate("/admin/edit-guide", {
          state: { guideId: userId },
        }):
          navigate("/admin/edit-guide", {
            state: { guideId: location?.state?.guideId },
          }),
      }}
      showViews={{ button1: true, button2: true }}
      tableColumns={guidesOrExplorerClassColumnHeader()}
      handleButtonClick={handleButtonClick}
    />
  );
}
