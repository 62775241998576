/**
 * column headers for Check In Details - Guide
 */

export const guideTableHeader = () => {
  return [
    { headerName: "No.", field: "id", flex: 1, minWidth: 50 },
    { headerName: "Guide Name", field: "guideFullName", flex: 9 },
    { headerName: "Check In", field: "check_in", flex: 2 },
  ];
};

/**
 * column headers from Check In Details - Explorer
 */

export const explorerTableHeader = () => {
  return [
    { headerName: "No.", field: "id", minWidth: 50, flex: 1 },
    { headerName: "Explorer Name", field: "explorerName", flex: 2 },
    { headerName: "Booking Status", field: "status", flex: 1 },
    { headerName: "Spot(s) Booked", field: "seatCount", flex: 1 },
    { headerName: "Check In", field: "isExplorerCheckedIn", flex: 1 },
    { headerName: "Booking Platform", field: "bookingPlatform", flex: 1 },
  ];
};
